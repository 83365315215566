input[type="range"]::-webkit-slider-runnable-track {
  height: 0.5rem;
  background-color: #ba71dc !important;
  width: 100%;
  cursor: pointer;
  background-color: #ba71dc;
  border-color: transparent;
  border-radius: 1rem;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1rem;
  background-color: #ba71dc;
  cursor: pointer;
  margin-top: -0.5rem;
}

.zeropa-range::-moz-range-thumb {
  width: 2rem;
  height: 2rem;
  margin-top: -0.25rem;
  background-color: #ba71dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .zeropa-range::-webkit-slider-thumb,
  .zeropa-range::-moz-slider-thumb {
    transition: none;
  }
}

input[type="range"]::-webkit-slider-thumb:active,
.zeropa-range::-moz-range-thumb:active {
  background-color: #9c3dcc;
}

.zeropa-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  background-color: #ba71dc;
  border-color: transparent;
  border-radius: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: none;
}
.zeropa-range:focus::-moz-range-thumb {
  box-shadow: none;
}
.zeropa-range::-moz-focus-outer {
  border: 0;
}
